import { DictionaryType } from "@/constant/dictionary";
import {
  BaseDictionariesApiFactory,
  BaseDictionary,
  BasePhysician,
  BasePhysiciansApiFactory,
  BaseProduct,
  BaseProductsApiFactory,
  BaseUser,
  BaseUsersApiFactory,
  KvpKey,
  KvpKeysApiFactory,
  MeetingChainEvidencesApiFactory,
  MeetingInfo,
  MeetingInfosApiFactory,
  MeetingPhysician,
  MeetingPhysiciansApiFactory,
  MeetingRecord,
  MeetingRecordsApiFactory,
  MeetingSchedule,
  MeetingSetout,
  MeetingSetoutsApiFactory,
  MeetingStaff,
  MeetingStaffsApiFactory,
  SchedulePhysicianMarketer,
  SchedulePhysicianMarketersApiFactory,
  StaffTerritoriesApiFactory,
  StaffTerritory,
  Vault,
  VaultsApiFactory,
  VUserMeetingInfo,
  VUserMeetingInfoHhesApiFactory,
  VUserMeetingInfoSpsApiFactory,
  VUserMeetingInfoSqsApiFactory,
  VUserMeetingInfoWcsApiFactory,
  VUserMeetingInfoZbsApiFactory,
  VUserMeetingInfoZxesApiFactory,
  WechatPhysician,
} from "@/generated";
import buildQuery from "odata-query";
import globalAxios from "axios";
import { mapValues } from "lodash";
import { tryParseJSON, returnApiData, islocationHref } from "@/utils/common";
import { MeetingOnlineInfo } from "@/type/meeting-online-info";
import { LoginResponse } from "@/type/login";
import { PaginationParams } from "@/type/api";
import { genOdataQueryPageOptions } from "@/utils/odata";
import { useViewMode } from "@/hooks/useViewMode";
import { VUserMeetingInfosApiFactory } from "@/generated/api/vuser-meeting-infos-api";
import { MEETING_STATUS } from "@/constant/meeting";
import { MeetingViewsApiFactory } from "@/generated/api/meeting-views-api";
import { PhysicianPhoneAppealParams } from "@/type/meeting";

const { isPc } = useViewMode();
globalAxios;
// 获取本人同级 所有上级、及所有下属员工
export const GetUserTerritoryRelated = async (
  territory_id: any,
  user_id: any,
  searchContent: any,
  type: any,
  unitePrincipal: any,
  meetingPrincipal: any,
  is_epay: boolean
) => {
  let url = `/api/webmeetingapi/api/MyAccount/GetUserTerritory`;
  let data = {};
  // 负责人：本人、上级
  if (type == "owner") {
    if (searchContent) {
      data = {
        condition: searchContent,
        exclude_user_ids: [],
        page_size: 100,
        page_index: 0,
      };
      if (unitePrincipal) {
        // “会议负责人”和“会议联合负责人”应该不允许选择同一个人
        let idsArr: any = [];
        unitePrincipal.forEach((item: any) => {
          idsArr.push(item.value.split(",")[0]);
        });
        data.exclude_user_ids = idsArr;
      }
    } else {
      url = `/api/webmeetingapi/api/MyAccount/GetUserTerritoryRelated?territory_id=${territory_id}&need_self=true&need_parent=true&need_sub=false&need_sibling=false&page_size=100&page_index=0`;
    }
  }
  // 联合负责人：上级、同级、下级
  if (type == "unite_owner") {
    if (searchContent) {
      data = {
        condition: searchContent,
        exclude_user_ids: [user_id],
        page_size: 100,
        page_index: 0,
      };
      if (meetingPrincipal) {
        // “会议负责人”和“会议联合负责人”应该不允许选择同一个人
        let idsArr: any = [];
        meetingPrincipal.forEach((item: any) => {
          idsArr.push(item.value.split(",")[0]);
        });
        data.exclude_user_ids = data.exclude_user_ids.concat(idsArr);
      }
    } else {
      url = `/api/webmeetingapi/api/MyAccount/GetUserTerritoryRelated?territory_id=${territory_id}&need_self=false&need_parent=true&need_sub=true&need_sibling=true&page_size=100&page_index=0`;
    }
  }
  // 申请人：本人、上级、同级、下级
  if (type == "applicant") {
    if (searchContent) {
      data = {
        condition: searchContent,
        exclude_user_ids: [],
        page_size: 100,
        page_index: 0,
      };
      if (is_epay) {
        data["only_epay"] = true;
      }
    } else {
      let ePayTxt = is_epay ? `&only_epay=true` : "";
      url = `/api/webmeetingapi/api/MyAccount/GetUserTerritoryRelated?territory_id=${territory_id}${ePayTxt}&need_self=true&need_parent=true&need_sub=true&need_sibling=true&page_size=100&page_index=0`;
    }
  }
  // 参会人：上级、同级、下级
  if (type == "joiner") {
    if (searchContent) {
      data = {
        condition: searchContent,
        exclude_user_ids: [user_id],
        page_size: 100,
        page_index: 0,
      };
      if (is_epay) {
        data["only_epay"] = true;
      }
    } else {
      let ePayTxt = is_epay ? `&only_epay=true` : "";
      url = `/api/webmeetingapi/api/MyAccount/GetUserTerritoryRelated?territory_id=${territory_id}${ePayTxt}&need_self=true&need_parent=true&need_sub=true&need_sibling=true&page_size=20&page_index=0`;
    }
  }
  let res: any = "";
  if (searchContent) {
    res = await globalAxios.post(url, data);
  } else {
    res = await globalAxios.get(url);
  }
  return {
    array: res.data.data as any as [],
    count: res.data.data_count,
  };
};
// 飞检
export const getMeetingInspections = async (meeting_info_id: any) => {
  const url = `/api/standard/OData/MeetingInspections?$filter=meeting_info_id eq '${meeting_info_id}'`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 获取会议数据状态
export const getMeetingInfosDataStatus = async (
  meeting_info_id: any,
  key: any
) => {
  // &$select=${key}, meeting_info_id
  const url = `/api/standard/OData/MeetingInfos?$filter=meeting_info_id eq '${meeting_info_id}'`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 获取会议数据状态 字段限制
export const getMeetingInfosSaveingStatus = async (
  meeting_info_id: any,
  key: any
) => {
  // &$select=${key}, meeting_info_id
  const url = `/api/standard/OData/MeetingInfos?$filter=meeting_info_id eq '${meeting_info_id}'
  &$select=data_status,data_status_info,approval_status,approval_status_info,meeting_principal_status,meeting_applicant_status,meeting_info_id`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 查询签到列表基本信息
export const getVMeetingInfos = async (meeting_info_id: any) => {
  const url = `/api/standard/OData/VMeetingInfos?$filter=meeting_info_id eq '${meeting_info_id}'`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 查询签到列表
export const getVMeetingEsignins = async (meeting_info_id: any) => {
  const url = `/api/standard/OData/VMeetingEsignins?$filter=meeting_info_id eq '${meeting_info_id}'&$orderby=sequence asc`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 根据用户id和会议id查收用户所在会议的角色
export const VUserMeetingRoles = async (user_id: any, meeting_info_id: any) => {
  const url = `/api/standard/OData/VUserMeetingRoles?$filter=user_id eq '${user_id}' and meeting_info_id eq '${meeting_info_id}'`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 获取服务器当前时间
export const GetGenericNow = async () => {
  const url = `/api/webmeetingapi/Generic/now`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 根据医生id获取对应的负责代表
export const GetPhysicianAlignUser = async (physician_ids: any) => {
  const url = `/api/webmeetingapi/api/Meeting/GetPhysicianAlignUser?physician_ids=${physician_ids}`;
  const res: any = await globalAxios.get(url);
  return res.data.data as any as [];
};
// 添加费用
export const deleteMeetingPoCosts = async (meeting_po_cost_id: any) => {
  const url = `/api/standard/MeetingPoCosts/${meeting_po_cost_id}`;
  const res: any = await globalAxios.delete(url);
  return res.data.entity;
};
export const putMeetingPoCosts = async (data: any) => {
  const url = "/api/standard/MeetingPoCosts";
  const res: any = await globalAxios.put(url, data);
  return res.data.entity;
};
export const addMeetingPoCosts = async (data: any) => {
  const url = "/api/standard/MeetingPoCosts";
  const res: any = await globalAxios.post(url, data);
  return res.data.entity;
};
// 登录日志
export const addLogUserLogins = async (data: any) => {
  const url = "/api/standard/LogUserLogins";
  const res: any = await globalAxios.post(url, data);
  return res.data.entity;
};
// 删除会议
export const deleteMeetingFn = async (data: any) => {
  const url = `/api/webmeetingapi/api/Meeting/Delete?meeting_info_id=${data.meeting_info_id}`;
  const res: any = await globalAxios.post(url);
  return res.data;
};
// 终止的会议
export const cancelMeetingFn = async (data: any) => {
  const url = `/api/webmeetingapi/api/Meeting/Cancel`;
  const res: any = await globalAxios.post(url, data);
  return res.data;
};
// 会议变更申请人
export const ChangeApplicant = async (data: any) => {
  const url = "/api/webmeetingapi/api/Meeting/ChangeApplicant";
  const res: any = await globalAxios.post(url, data);
  return res.data;
};
// 会议变更负责人
export const ChangePrincipal = async (data: any) => {
  const url = "/api/webmeetingapi/api/Meeting/ChangePrincipal";
  const res: any = await globalAxios.post(url, data);
  return res.data;
};
// 会议基本信息修改
export const meetingChange = async (data: any) => {
  const url = "/api/webmeetingapi/api/Meeting/Change";
  const res: any = await globalAxios.post(url, data);
  return res.data;
};
// 变更讲者
export const changeSpeaker = async (data: any) => {
  const url = "/api/webmeetingapi/api/Meeting/ChangeSpeaker";
  const res: any = await globalAxios.post(url, data);
  return res.data;
};
// 图片打水印
export const addWaterMark = async (data: any) => {
  // const url = `/api/aws/api/Images/AddWaterMark`;
  const url = `/api/webmeetingapi/api/Meeting/AddWaterMark`;
  const res: any = await globalAxios.post(url, data);
  return res.data;
};
// 保存会议信息
export const meetingSave = async (data: any) => {
  const url = "/api/webmeetingapi/api/Meeting/Save";
  const res: any = await globalAxios.post(url, data);
  return res.data;
};
// 获取保存会议成功状态
export const GetMeetingDataStatus = async (meeting_info_id: any) => {
  const url = `/api/webmeetingapi/api/Meeting/GetMeetingDataStatus?meeting_info_id=${meeting_info_id}`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 获取审批日志
export const GetPOApprovalProcess = async (
  meeting_info_id: any,
  meeting_po_id: any
) => {
  let poId = meeting_po_id ? `&meeting_po_id=${meeting_po_id}` : "";
  const url = `/api/webmeetingapi/Epay/GetPOApprovalProcess?meeting_info_id=${meeting_info_id}${poId}`;
  // const url = `/api/webmeetingapi/Epay/GetPOApprovalProcess?meeting_po_id=95334`;
  const res: any = await globalAxios.get(url);
  return res.data.data as any as [];
};
// 召回会议
export const CallBackMeeting = async (data: any) => {
  const url = `/api/webmeetingapi/api/Meeting/Callback`;
  const res: any = await globalAxios.post(url, data);
  return res.data;
};
// 检查是否能召回
export const CheckCallback = async (meeting_info_id: any) => {
  const url = `/api/webmeetingapi/Epay/CheckCallback?meeting_info_id=${meeting_info_id}`;
  const res: any = await globalAxios.post(url);
  return res.data;
};
// 检查预算金额
export const CheckBudgetFn = async (data: any) => {
  const url = "/api/webmeetingapi/Epay/CheckBudget";
  const res: any = await globalAxios.post(url, data);
  return res.data as any as [];
};
// 检查供应商
export const CheckVendorFn = async (data: any) => {
  const url = "/api/webmeetingapi/Epay/CheckVendor";
  const res: any = await globalAxios.post(url, data);
  return res.data as any as [];
};
// 检查讲者
export const CheckSpeakerFn = async (data: any) => {
  const url = "/api/webmeetingapi/Epay/CheckSpeaker";
  const res: any = await globalAxios.post(url, data);
  return res.data as any as [];
};
// 检查讲者单次费用金额
export const getSpeakerFee = async (expense_type_id: any, speaker_id: any) => {
  const url = `/api/webmeetingapi/Epay/GetSpeakerFee?expense_type_id=${expense_type_id}&speaker_id=${speaker_id}`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 成本中心
export const getCostCenters = async () => {
  const url = `/api/standard/OData/BaseCostCenters`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 预算号 信息
export const getGetBudget = async (userId: any, year: any) => {
  const url = `/api/webmeetingapi/Epay/GetBudget?user_id=${userId}&year=${year}`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 费用项
export const getExpenseTypes = async (filter: any = {}) => {
  const url = `/api/standard/OData/BaseExpenseTypes` + buildQuery({ filter });
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 供应商
export const getVendors = async () => {
  const url = `/api/standard/OData/BaseVendors`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 获取所有员工
export const getVMeetingStaffAlls = async (meeting_info_id: any) => {
  const url = `/api/standard/OData/VMeetingStaffAlls?$filter=meeting_info_id eq '${meeting_info_id}'`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// h5 会议邀请查询
export const GetHCPInvitation = async (
  data: any,
  wechat_open_id: any,
  is_accept: any
) => {
  let url = "";
  if (is_accept == -2) {
    url = `/api/webmeetingapi/api/WeChatMeeting/GetHCPInvitation?open_id=${wechat_open_id}&page_size=${data.pageSize}&page_index=${data.current}`;
  } else {
    url = `/api/webmeetingapi/api/WeChatMeeting/GetHCPInvitation?open_id=${wechat_open_id}&is_accept=${is_accept}&page_size=${data.page_size}&page_index=${data.page_index}`;
  }
  const res: any = await globalAxios.get(url);
  // return res.data;
  return res.data.data;
};
// h5 会议详情定义字段
export const getMeetingDetails = async (
  meeting_info_id: any,
  wechat_open_id: any
) => {
  const url = `/api/webmeetingapi/api/WeChatMeeting/GetHCPMeetingInfo?open_id=${wechat_open_id}&meeting_info_id=${meeting_info_id}`;
  const res: any = await globalAxios.get(url);
  return res.data.data;
};
// 讲者签约页面获取会议基本信息
export const getSignMeetingInfos = async (meeting_info_id: any) => {
  const url = `/api/standard/OData/VMeetingInfos?$filter=meeting_info_id eq '${meeting_info_id}'`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
/** 获取会议录屏列表 */
export const getVideoList = async (meeting_info_id: any) => {
  const url = `/api/standard/OData/MeetingOnlineRecords?$filter=meeting_info_id eq '${meeting_info_id}'`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 手动推送 https://lundbeck-event-mobile-dev.deloitte.cc/api/webmeetingapi/api/WeChat/SendEvaluation?meeting_id=8ae5431a-ced9-4d94-b270-a3eaafbb3ec7
export const GetSendEvaluation = async (meeting_id: any) => {
  const url = `/api/webmeetingapi/api/WeChat/SendEvaluation?meeting_id=${meeting_id}`;
  const res: any = await globalAxios.get(url);
  return res.data.code;
};
// 按医生和日期获取会议清单
export const GetHCPMeeting = async (open_id: any, meeting_date: any) => {
  const url = `/api/webmeetingapi/api/WeChatMeeting/GetHCPMeetingDay?open_id=${open_id}&meeting_date=${meeting_date}`;
  const res: any = await globalAxios.get(url);
  return res.data.data;
};
// 按医生和年月获取月份中的会议列表
export const GetHCPMeetingMonth = async (open_id: any, month: any) => {
  const url = `/api/webmeetingapi/api/WeChatMeeting/GetHCPMeetingMonth?open_id=${open_id}&month=${month}`;
  const res: any = await globalAxios.get(url);
  return res.data.data;
};
// 按医生获取当前周的会议数
export const VPhysicianMeetingNoWeeks = async (physician_id: any) => {
  const url = `/api/standard/OData/VPhysicianMeetingNoWeeks?$filter=physician_id eq '${physician_id}'`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 个人中心
export const GetMyMeetingStatics = async (data: any) => {
  const url = "/api/webmeetingapi/api/MyAccount/GetMyMeetingStatics";
  const res: any = await globalAxios.post(url, data);
  return res.data.data as any as [];
};
export const getMeetingInfoListNew = async (
  territoryId?: any,
  paginationParams?: PaginationParams,
  meeting_state?: any,
  meeting_name?: any,
  meeting_type?: any,
  { isCount = false }: { isCount?: boolean } = {}
) => {
  let res: any;
  let mName = meeting_name
    ? ` and contains(meeting_name,'${meeting_name}')`
    : "";
  let mType = meeting_type
    ? ` and contains(meeting_type,'${meeting_type}')`
    : "";
  let fTxt = `?$filter=contains(user_ids,',${territoryId},')${mName}${mType}`;
  let paginationTxt = `&$skip=${
    paginationParams.current * paginationParams.pageSize
  }&$top=${paginationParams.pageSize}`;
  let filterTxt = `${fTxt}${paginationTxt}&$orderby=meeting_start desc&$count=true`;
  let url = "";
  if (meeting_state == 1) {
    url = `/api/standard/OData/VUserMeetingInfoSqs${filterTxt}`;
    res = await globalAxios.get(url);
  }
  if (meeting_state == 2) {
    url = `/api/standard/OData/VUserMeetingInfoSps${filterTxt}`;
    res = await globalAxios.get(url);
  }
  if (meeting_state == 3) {
    url = `/api/standard/OData/VUserMeetingInfoZbs${filterTxt}`;
    res = await globalAxios.get(url);
  }
  if (meeting_state == 4) {
    url = `/api/standard/OData/VUserMeetingInfoZxes${filterTxt}`;
    res = await globalAxios.get(url);
  }
  if (meeting_state == 5) {
    url = `/api/standard/OData/VUserMeetingInfoHhes${filterTxt}`;
    res = await globalAxios.get(url);
  }
  if (meeting_state == 6) {
    url = `/api/standard/OData/VUserMeetingInfoWcs${filterTxt}`;
    res = await globalAxios.get(url);
  }
  if (meeting_state == 7) {
    url = `/api/standard/OData/VUserMeetingInfoShes${filterTxt}`;
    res = await globalAxios.get(url);
  }
  return returnApiData(isPc, res);
};
//
export const getMeetingInfoStopList = async (data: any) => {
  let fTxt = `?$filter=contains(user_ids,',${data.user_id},')`;
  let paginationTxt = `&$skip=${
    data.pageNum * data.pageSize - data.pageSize
  }&$top=${data.pageSize}`;
  let filterTxt = `${fTxt}${paginationTxt}&$orderby=meeting_start desc&$count=true`;
  let url = `/api/standard/OData/VUserMeetingInfoZzes${filterTxt}`;
  // let sUrl = `/api/standard/odata/VUserMeetingInfoZzes?$skip=${data.pageNum * data.pageSize - data.pageSize}&$top=${data.pageSize}&$count=true`
  const res = await globalAxios.get(url);
  return {
    array: res.data,
    count: res.count,
  };
};
/** 获取会议信息(单个) */
export const getMeetingInfos = async (meeting_info_id: string) => {
  const url = `/api/webmeetingapi/api/Meeting/GetMeetingFull?meeting_info_id=${meeting_info_id}`;
  const res: any = await globalAxios.get(url);
  return res.data.data as any as [];
};
//  查询会议评价记录
export const GetMeetingPhysicianEvaluations = async (
  meeting_info_id: any,
  physician_id: any
) => {
  const url = `/api/standard/OData/MeetingPhysicianEvaluations?$filter=meeting_info_id eq '${meeting_info_id}' and physician_id eq '${physician_id}'`;
  const res: any = await globalAxios.get(url);
  return res.data as any as [];
};
// 获取部分会议信息
export const GetMeetingByForm = async (
  meeting_info_id: any,
  get_forms: any
) => {
  const url = `/api/webmeetingapi/api/Meeting/GetMeeting?meeting_info_id=${meeting_info_id}&get_forms=${get_forms}`;
  const res: any = await globalAxios.get(url);
  return res.data.data as any;
};
// 获取合同地址
export const GetContractFileById = (contractids: any) => {
  let location = islocationHref();
  const url =
    location +
    `/api/webmeetingapi/api/sign/GetContractFile?contractids=${contractids}`;
  // const res: any = await globalAxios.get(url);
  return url;
};
// 获取讲者签到情况
export const getMeetingSpeaker = async (meeting_speaker_id: any) => {
  const url = `/api/standard/OData/MeetingSpeakers?$filter= meeting_speaker_id eq '${meeting_speaker_id}'&$select=is_be_present,meeting_info_id,meeting_speaker_id,is_signin_sales`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 修改讲者基本信息
export const putMeetingSpeaker = async (data: any) => {
  const url = "/api/standard/MeetingSpeakers";
  const res: any = await globalAxios.put(url, data);
  return res.data.entity;
};
// 修改计划基本信息
export const putMeetingSetouts = async (data: any) => {
  const url = "/api/standard/MeetingSetouts";
  const res: any = await globalAxios.put(url, data);
  return res.data.entity;
};
// 修改员工基本信息
export const putMeetingStaffs = async (data: any) => {
  const url = "/api/standard/MeetingStaffs";
  const res: any = await globalAxios.put(url, data);
  return res.data.entity;
};
// 获取员工签到情况
export const getMeetingStaffs = async (meeting_staff_id: any) => {
  const url = `/api/standard/OData/MeetingStaffs?$filter= meeting_staff_id eq '${meeting_staff_id}'&$select=is_be_present,meeting_info_id,meeting_staff_id`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 修改医生基本信息
export const putMeetingPhysicians = async (data: any) => {
  const url = "/api/standard/MeetingPhysicians";
  const res: any = await globalAxios.put(url, data);
  return res.data.entity;
};
// 获取医生签到情况
export const getMeetingPhysicians = async (meeting_physician_id: any) => {
  const url = `/api/standard/OData/MeetingPhysicians?$filter= meeting_physician_id eq '${meeting_physician_id}'&$select=is_be_present,meeting_info_id,meeting_physician_id,is_signin_sales`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
export const getMeetingPhysiciansById = async (
  meeting_info_id: any,
  speaker_id: any
) => {
  const url = `/api/standard/OData/MeetingPhysicians?$filter= meeting_info_id eq '${meeting_info_id}' and speaker_id eq '${speaker_id}' and sys_status eq 1`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 参会非CRM医生修改为CRM已有医生
export const MapExistPhysician = async (data: any) => {
  const url = "/api/webmeetingapi/api/Meeting/MapExistPhysician";
  const res: any = await globalAxios.post(url, data);
  return res.data;
};
// 绑定企业微信
export const weChatWecomBinding = async (data: any) => {
  const url = "/api/webmeetingapi/api/WeChat/WecomBinding";
  const res: any = await globalAxios.post(url, data);
  return res.data.data;
};
// 修改微信医生基本信息
export const putPhysicianWechats = async (data: any) => {
  const url = "/api/standard/PhysicianWechats";
  const res: any = await globalAxios.put(url, data);
  return res.data.entity;
};
export const addPhysicianWechats = async (data: any) => {
  const url = "/api/standard/PhysicianWechats";
  const res: any = await globalAxios.post(url, data);
  return res.data.entity;
};
export const getPhysicianWechatsByPhysicianId = async (physician_id: any) => {
  const url = `/api/standard/OData/PhysicianWechats?$filter=physician_id eq '${physician_id}'`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 获取预定信息
export const getMeetingOnlineInfos = async (meeting_setout_id: any) => {
  const url = `/api/standard/OData/MeetingOnlineInfos?$filter=meeting_info_id eq '${meeting_setout_id}'`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// h5查询医生
export const searchBasePhysicians = async (
  physician_name: any,
  institution_name: any
) => {
  const url = `/api/standard/OData/BasePhysicians?$filter=institution_name eq '${institution_name}' and contains(physician_name, '${physician_name}')
  &$skip=0&$top=3`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// h5精确查询医生
export const searchRealBasePhysicians = async (
  physician_name: any,
  institution_name: any,
  department_name: any
) => {
  const depTxt = department_name
    ? ` and department_name eq '${department_name}'`
    : "";
  const url = `/api/standard/OData/BasePhysicians?$filter=institution_name eq '${institution_name}' and physician_name eq '${physician_name}'${depTxt}
  &$skip=0&$top=3`;
  const res: any = await globalAxios.get(url);
  return res.data;
};

// 在线签约
export const sendOnlineSign = async (data: any) => {
  const url = `/api/webmeetingapi/api/sign/OnlineSign`;
  const res: any = await globalAxios.post(url, data);
  return res.data;
};
// h5签到
export const MeetingSignIn = async (
  meeting_info_id: any,
  physician_openId: any
) => {
  const url = `/api/webmeetingapi/api/WeChat/MeetingSignIn?meeting_info_id=${meeting_info_id}&physician_openId=${physician_openId}`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 获取会议计划数据
export const getMeetingPlans = async (meeting_info_id: any) => {
  const url = `/api/standard/OData/MeetingPlans?$filter=meeting_info_id eq '${meeting_info_id}'`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
// 是否签约过数据
export const getContracts = async (meeting_info_id: any, physician_id: any) => {
  const url = `/api/standard/OData/MeetingSpeakerContracts?$filter=meeting_info_id eq '${meeting_info_id}' and physician_id eq '${physician_id}'`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
export const addContracts = async (data: any) => {
  const url = "/api/standard/MeetingSpeakerContracts";
  let res;
  if (data.meeting_speaker_contract_id) {
    res = await globalAxios.put(url, data);
  } else {
    res = await globalAxios.post(url, data);
  }
  return res.data.entity;
};
// 获取签到表
export const getMeetingEvidences = async (meeting_info_id: any) => {
  const url = `/api/standard/OData/MeetingEvidences?$filter=meeting_info_id eq '${meeting_info_id}' and evidence_type eq '电子签到表'`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
export const addMeetingEvidences = async (data: any) => {
  const url = "/api/standard/MeetingEvidences";
  let res;
  if (data.meeting_evidence_id) {
    res = await globalAxios.put(url, data);
  } else {
    res = await globalAxios.post(url, data);
  }
  return res.data.entity;
};
// 会议评价处数据 暂时未调用
export const getAppraise = async (meeting_info_id: any) => {
  const url = `api/standard/OData/MeetingInfos?$filter=meeting_info_id eq '${meeting_info_id}'&$expand=meeting_info__meeting_physician_speaker_evaluations,meeting_info__meeting_physician_evaluations`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
export const getMeetingInfo = async (key: string) => {
  const expand = [
    // "meeting_info__meeting_plans",
    // {
    //   meeting_info__meeting_setouts: {
    //     expand: [
    //       "meeting_setout__meeting_reminds",
    //       "meeting_setout__meeting_questionnaires",
    //       "meeting_setout__meeting_posters",
    //     ],
    //   },
    // },
    {
      meeting_info__meeting_schedules: {},
    },
    "meeting_info__meeting_physicians", // physician/physician__physician_wechats
    "meeting_info__meeting_staffs",
    "meeting_info__meeting_unite_principals",
    "meeting_info__meeting_evaluations",
    "meeting_info__speakers_evaluations",
    "meeting_info__meeting_physician_speaker_evaluations",
    "meeting_info__meeting_physician_evaluations",
    "meeting_info__meeting_schedule_speakers",
    // "meeting_info__meeting_before_datas/vault",
    "meeting_info__meeting_speakers",
    "meeting_info__meeting_online_infos",
  ];
  const res = await MeetingInfosApiFactory().get_2(key, {
    odata: buildQuery({ expand }),
  });
  return res.data as any as MeetingInfo;
};

/** 获取字典 */
export const fetchBaseDictionaries = async (type?: DictionaryType) => {
  const odata = type
    ? buildQuery({ filter: { parent_id: type } })
    : buildQuery({ filter: { not: { parent_id: 0 } } });
  const res = await BaseDictionariesApiFactory().get({
    odata,
  });
  return res.data as any as BaseDictionary[];
};
// 查询讲者信息
export const getBaseSpeakers = async (data: any) => {
  const url = `/api/standard/OData/BaseSpeakers?$filter=physician_id eq '${data.physician_id}'`;
  const res: any = await globalAxios.get(url);
  return res.data;
};
/** 获取医生列表 */
export const fetchBasePhysicians = async (
  filter?: any,
  {
    isFuzzy = false,
    paginationParams,
  }: { isFuzzy?: boolean; paginationParams?: PaginationParams } = {}
) => {
  const res = await BasePhysiciansApiFactory().get({
    odata: buildQuery({
      expand: ["physician__physician_wechats"],
      ...genOdataQueryPageOptions(paginationParams),
      filter: isFuzzy
        ? mapValues(filter, (item) => ({ contains: item }))
        : filter,
    }),
  });
  return res.data as any as BasePhysician[];
};

/** 获取员工列表 */
// export const fetchBaseMarketers = async (
//   filter?: any,
//   { isFuzzy = false }: { isFuzzy?: boolean } = {}
// ) => {
//   const expand = [
//     "staff__meeting_staffs/meeting_staff__marketer_jurisdictions",
//   ];
//   const res = await BaseMarketersApiFactory().get({
//     odata: buildQuery({
//       expand,
//       filter: isFuzzy
//         ? mapValues(filter, (item) => ({ contains: item }))
//         : filter,
//     }),
//   });
//   return res.data as any as BaseMarketer[];
// };

/** 获取涉及产品 */
export const fetchBaseProducts = async () => {
  const res = await BaseProductsApiFactory().get();
  return res.data as any as BaseProduct[];
};

/** 创建保存准备 */
export const savePrepare = async (data: any) => {
  const url = "/api/webmeetingapi/api/Meeting/Prepare";
  const res = await globalAxios.post(url, data);
  const resData: any = res.data;
  return resData;
};
/** 创建保存会议 */
export const createMeeting = async (data: any) => {
  const url = "/api/webmeetingapi/api/Meeting/Create";
  // const method = 'POST'
  const res = await globalAxios.post(url, data);
  const resData: any = res.data;

  if (resData?.code !== "Success") {
    throw new Error(resData?.message);
  }

  if (resData.data === "修改成功！") return;

  return resData.data as string;
};

/** 提交会议 */
export const meetingSubmit = async (meeting_info_id: any) => {
  const url = `/api/webmeetingapi/api/Meeting/Submit?meeting_info_id=${meeting_info_id}`;
  const res = await globalAxios.post(url);
  return res.data;
};
/** 会议保存提交 */
export const meetingSaveSubmit = async (data: any) => {
  const url = `/api/webmeetingapi/api/Meeting/SaveSubmit`;
  const res = await globalAxios.post(url, data);
  return res.data;
};
export const submitMeeting = async (meeting_info_id: string) => {
  const url = "/api/webmeetingapi/api/Meeting/Apply";

  await globalAxios.post(url, {
    meeting_info_id,
  });
};

/** 保存会议信息 */
export const saveMeetingInfo = async (data: Partial<MeetingInfo>) => {
  const { meeting_info_id, ...rest } = data;
  if (!meeting_info_id) throw Error("meeting_info_id is undefined");
  await MeetingInfosApiFactory().patch(meeting_info_id, rest as any);
};

/** 申请在线会议 api/tencentmeeting/meet/createmeet → api/tencentmeeting/meeting/CreateMeeting*/
export const applyOnlineMeeting = async (data: any) => {
  const url = "/api/tencentmeeting/meeting/CreateMeeting";
  const res = await globalAxios.post(url, data);
  return res.data;
};

/** 修改在线会议 api/tencentmeeting/meet/editmeet→ api/tencentmeeting/meeting/CreateMeeting*/
export const putOnlineMeeting = async (data: any) => {
  const url = "/api/tencentmeeting/meeting/CreateMeeting";
  const res = await globalAxios.post(url, data);
  return res.data;
};

/** 获取在线会议配置 api/tencentmeeting/meet/createmeet → api/tencentmeeting/meeting/CreateMeeting*/
export const fetchOnlineMeetingConfig = async (meeting_setout_id: string) => {
  const url = "/api/tencentmeeting/meeting/CreateMeeting";

  const res: MeetingSetout = (await MeetingSetoutsApiFactory().get_2(
    meeting_setout_id
  )) as any;

  return tryParseJSON(res.setting_online_meeting);
};

/** 企业微信登录 /api/weixin/login -> /api/weixin/QYWeChat/GetUserInfo */
export const wxLogin = async (code: string) => {
  const url = `/api/weixin/QYWeChat/GetUserInfo`;

  const res = await globalAxios.get(url, { params: { code } });
  const resData: LoginResponse = res.data;

  if (resData.errcode !== 0) {
    throw new Error(resData.errmsg);
  }

  return resData;
};
/** 获取用户可创建会议类型 */
export const getVUserMeetingTypes = async (user_id: string) => {
  const url = `/api/standard/OData/VUserMeetingTypes?$filter=user_id eq '${user_id}'&$orderby=sequence asc`;
  const res = await globalAxios.get(url);
  return res.data;
};
/** 获取用户信息 */
// export const fetchUserInfo = async ({
//   user_login_name,
//   user_id,
// }: {
//   user_login_name?: string;
//   user_id?: string;
// }) => {
//   // user__staff_territories-> user__base_territories
//   const expand = [
//     "user__base_territories",
//   ];
//   const res = await BaseUsersApiFactory().get({
//     odata: buildQuery({
//       filter: { user_login_name, user_id },
//       expand,
//     }),
//   });
//   return (res.data as any)[0] as BaseUser;
// };
export const loginFn = async (data: any) => {
  // 管理员登录
  let url = `/api/standard/OData/CnfAdminUsers?$filter=(tolower(user_id) eq tolower('${data.username}') or tolower(email) eq tolower('${data.user_id}')) and password eq '${data.password}'`;
  const res = await globalAxios.get(url);
  return res;
};
export const fetchAdminScopeUsers = async (data: any) => {
  // 管理员登录
  let url = `/api/standard/OData/CnfAdminScopeUsers?$filter=tolower(admin_user_id) eq tolower('${data.admin_userId}') and (user_id eq 'all' or user_id eq '${data.user_id}')`;
  const res = await globalAxios.get(url);
  return res;
};
export const getNow = async () => {
  // 获取服务器时间
  let url = `/api/webmeetingapi/Generic/Now`;
  const res = await globalAxios.get(url);
  return res;
};
export const fetchUserInfo = async ({
  user_login_name,
  user_id,
}: {
  user_login_name?: string;
  user_id?: string;
}) => {
  let filter = "";
  if (user_login_name) {
    filter = `$filter=user_login_name eq '${user_login_name}'`;
  }
  if (user_id) {
    filter = `$filter=user_id eq '${user_id}'`;
  }
  const url = `/api/standard/OData/BaseUsers?${filter}&$expand=user__base_territories, user__cnf_user_roles, user__cnf_user_view_territories($expand=territory),user__cnf_user_roles($expand=role($expand=role__cnf_role_permissions))`;
  const res = await globalAxios.get(url);
  return res.data[0];
};

/** 保存会议准备信息 */
export const saveMeetingPrepareInfo = async (data: Partial<MeetingSetout>) => {
  const res = await MeetingSetoutsApiFactory().put(data as any);
  return res.data;
};

/** 新增医生 */
export const addMeetingPhysicians = async (data: Partial<MeetingPhysician>) => {
  await MeetingPhysiciansApiFactory().put_7(data as any);
};

/** 新增员工 */
export const addMeetingStaffs = async (data: Partial<MeetingStaff>) => {
  await MeetingStaffsApiFactory().put_7(data as any);
};

/** 获取员工领域 废 */
export const fetchStaffTerritoryList = async (filter: any = {}) => {
  const res = await StaffTerritoriesApiFactory().get({
    odata: { filter },
  });
  return res.data as any as StaffTerritory[];
};

/** 获取会议单个日程信息 */
export const fetchOneMeetingScheduleInfo = async (scheduleId: string) => {
  // const res = await MeetingSchedulesApiFactory().get_2(scheduleId);
  const res = { data: {} };
  return res.data as any as MeetingSchedule;
};

/** 获取材料列表 */
export const fetchMaterialList = async () => {};
/** 删除证据链 新 **/
export const delMeetingChains = async (meeting_evidence_id) => {
  const url = `/api/standard/MeetingEvidences/${meeting_evidence_id}`;
  const res = globalAxios.delete(url);
  return res;
};
/** 保存证据链 新 **/
export const saveMeetingChains = async (data: any) => {
  const url = "/api/standard/MeetingEvidences";
  // const res: any = await globalAxios.post(url, data);
  let ajaxArr = [];
  for (let i = 0; i < data.meeting_evidence.length; i++) {
    if (data.meeting_evidence[i].meeting_evidence_id) {
      ajaxArr.push(globalAxios.put(url, data.meeting_evidence[i]));
    } else {
      ajaxArr.push(globalAxios.post(url, data.meeting_evidence[i]));
    }
  }

  return Promise.all(ajaxArr);
};
/** 保存证据链 */
export const saveMeetingEvidenceChains = async (
  data: Partial<MeetingInfo>,
  before?: Partial<MeetingInfo>
) => {
  if (before) {
    const beforeEvidenceList =
      before.meeting_info__meeting_chain_evidences?.map(
        (item) => item.chain_evidence_id
      );

    if (beforeEvidenceList?.length) {
      await MeetingChainEvidencesApiFactory()._delete_1(
        true,
        beforeEvidenceList
      );
    }
  }
  await MeetingChainEvidencesApiFactory().put_7(
    data.meeting_info__meeting_chain_evidences as any
  );
};

/** 发送公众号和企业微信邀请推送 */
export const sendPersonInvite = async (
  meetingInfo: Partial<MeetingInfo> & { userId?: string; msgUrl?: string },
  physician: {
    physician_id: string;
    channel: "公众号" | "企业微信";
    qywx_open_id: string;
  }[]
  // data: {
  //   meeting_info_id?: string;
  //   meeting_name?: string;
  //   meeting_start?: string;
  //   meeting_place?: string;
  //   physician: { physician_id: string; channel: "公众号" | "企业微信" }[];
  // }
) => {
  const url = "/api/webmeetingapi/api/WeChat/SendInvitationAsync";

  const data = {
    qy_url: meetingInfo.msgUrl,
    user_id: meetingInfo.userId,
    meeting_info_id: meetingInfo.meeting_info_id,
    meeting_name: meetingInfo.meeting_name,
    meeting_start: meetingInfo.meeting_start,
    meeting_place: meetingInfo.meeting_place,
    physician,
  };
  const res = await globalAxios.post(url, data);
  return res.data;
  // const success = res.data.code === "Success";
  // if (!success) {
  //   throw new Error();
  // }
};

/** 获取会议记录 废 */
export const fetchMeetingRecords = async (meetingInfoId: string) => {
  const res = await MeetingRecordsApiFactory().get({
    odata: {
      filter: { meeting_info_id: meetingInfoId },
      orderBy: "operation_time asc",
    },
  });
  return res.data as any as MeetingRecord[];
};

/** 删除会议 */
export const deleteMeetingInfo = async (id: string) => {
  await MeetingInfosApiFactory()._delete(id);
};

/** 获取config前的token */
export const getAuthToken = async () => {
  const url =
    "/api/login/auth?uid=event&password=1Holu3IKwRzd3tM8wd9ZMRxoOgX2k28o6QlwAZtswCk=";
  const res: any = await globalAxios.get(url);
  const urlS3 = `/api/aws/Config/S3`;
  const resSt: any = await globalAxios.get(urlS3, {
    headers: {
      authorization: "bearer " + res.data.token,
    },
  });
  return resSt.data;
};
/** 获取 s3 文件路径 */
export const fetchS3ObjectUrl = (key?: string) => {
  let location = islocationHref();
  const url = location + `/api/aws/AWSService/GetObject?keyName=${key}`;
  return url;
};

export const fetchS3OutsideUrl = (key?: string) => {
  let location = islocationHref();
  const url = location + `/api/aws/AWSService/GetObject?keyName=${key}`;
  return url;
};

/** 获取 vault 文件路径 */
export const fetchVaultUrl = (id?: string) => {
  const url = fetchS3ObjectUrl(`vault/${id}.pdf`);
  return url;
};
export const fetchVaultUrlByType = (id?: string, fileType?: any) => {
  const url = fetchS3ObjectUrl(`vault/${id}.${fileType}`);
  return url;
};

/** 获取 vault 数据 */
export const fetchVaultList = async (
  filter?: any,
  pageParams?: PaginationParams
) => {
  const res = await VaultsApiFactory().get({
    odata: { filter, count: true, ...genOdataQueryPageOptions(pageParams) },
  });
  return returnApiData(true, res) as any as Vault[];
  // return res.data as any as Vault[];
};
// epay 员工
export const getBaseUsers = async (data: any) => {
  let fUser = "";
  if (data.user_name) {
    fUser = ` and (contains(user_name, '${data.user_name}') or contains(user_id, '${data.user_name}'))`;
  }
  const path = `/api/standard/OData/BaseUsers?$count=true&$filter=is_epay eq 1 and is_onboard eq 1${fUser}&$skip=${
    data.pageNum * data.pageSize - data.pageSize
  }&$top=${data.pageSize}`;
  const res = await globalAxios.get(path);
  return {
    array: res.data,
    count: res.count,
  };
};
// epay 部门
export const getBaseLbDepartments = async (data: any) => {
  let fUser = "";
  if (data.user_name) {
    fUser = `&$filter=(contains(lb_department_name_cn, '${data.user_name}') or contains(lb_department_name_en, '${data.user_name}'))`;
  }
  const path = `/api/standard/OData/BaseLbDepartments?$count=true${fUser}&$skip=${
    data.pageNum * data.pageSize - data.pageSize
  }&$top=${data.pageSize}`;
  const res = await globalAxios.get(path);
  return {
    array: res.data,
    count: res.count,
  };
};
/** 获取领域员工列表 */
export const fetchTerritoryStaffList = async (
  filter?: any,
  paginationParams: PaginationParams = {}
) => {
  const res = await StaffTerritoriesApiFactory().get({
    odata: {
      count: true,
      filter,
      ...genOdataQueryPageOptions(paginationParams),
    },
  });
  return returnApiData(isPc, res) as StaffTerritory[];
};

/** 添加医生 */
export const addPhysician = async (user?: WechatPhysician) => {
  if (!user) return;
  const resBasePhysician = await BasePhysiciansApiFactory().put({
    physician_name: user?.pyhsician_name,
    institution_name: user.hospital_name,
    admin_title: user.professional_name,
    department_name: user.administrative_office_name,
  } as Partial<BasePhysician> as any);

  const physician = (resBasePhysician.data as any)?.entity as BasePhysician;
  return physician;
};

/** 添加参会医生 */
export const addMeetingPhysician = async (
  data: Partial<MeetingPhysician>[]
) => {
  const url = "/api/standard/MeetingPhysicians";
  const res: any = await globalAxios.post(url, data);
  return res.data.entity;
};
export const deleteMeetingPhysician = async (meeting_physician_id: any) => {
  const url = `/api/standard/MeetingPhysicians/${meeting_physician_id}`;
  const res: any = await globalAxios.delete(url);
  return res.data;
};
/** 添加参会员工 */
export const addedMeetingStaffs = async (data: Partial<MeetingPhysician>[]) => {
  const url = "/api/standard/MeetingStaffs";
  const res: any = await globalAxios.post(url, data);
  return res.data.entity;
};
export const deleteMeetingStaffs = async (meeting_staff_id: any) => {
  const url = `/api/standard/MeetingStaffs/${meeting_staff_id}`;
  const res: any = await globalAxios.delete(url);
  return res.data;
};
/** 新增分配员工 */
export const addMeetingVenueStaffs = async (
  data: Partial<MeetingPhysician>[]
) => {
  const url = "/api/standard/MeetingVenueStaffs";
  const res: any = await globalAxios.post(url, data);
  return res.data.entity;
};
export const deleteMeetingVenueStaffs = async (meeting_venue_staff_id: any) => {
  const url = `/api/standard/MeetingVenueStaffs/${meeting_venue_staff_id}`;
  const res: any = await globalAxios.delete(url);
  return res.data;
};
/** 新增分配医生 */
export const addMeetingVenuePhysician = async (
  data: Partial<MeetingPhysician>[]
) => {
  const url = "/api/standard/MeetingVenuePhysicians";
  const res: any = await globalAxios.post(url, data);
  return res.data.entity;
};
export const deleteMeetingVenuePhysician = async (
  meeting_venue_physician_id: any
) => {
  const url = `/api/standard/MeetingVenuePhysicians/${meeting_venue_physician_id}`;
  const res: any = await globalAxios.delete(url);
  return res.data;
};
/** 获取参会医生专属链接 */
export const createtOwnUrlFn = async (data: any) => {
  const url = "/api/tencentmeeting/meeting/CreateOwnUrl";
  const res: any = await globalAxios.post(url, data);
  return res.data;
};
/** 添加非目标参会医生 */
export const addUserContacts = async (data: any) => {
  const url = "/api/standard/UserContacts";
  const res: any = await globalAxios.post(url, data);
  return res.data.entity;
};
// 删除
export const deleteUserContacts = async (user_contact_id: any) => {
  const url = `/api/standard/UserContacts/${user_contact_id}`;
  const res: any = await globalAxios.delete(url);
  return res.data;
};
/** 获取用户相关会议列表 */
export const fetchUserRelatedMeeting = async (
  userId: string,
  filterObject: any = {},
  {
    timeRange,
    timeRangeInculdeMode,
  }: { timeRange?: Date[]; timeRangeInculdeMode?: boolean } = {}
) => {
  let dateFilterTxt = "";
  if (timeRange) {
    let mSt = timeRangeInculdeMode
      ? `meeting_start le ${timeRange?.[0]}`
      : `meeting_start ge ${timeRange?.[0]}`;
    let mEd = timeRangeInculdeMode
      ? `meeting_end ge ${timeRange?.[1]}`
      : `meeting_end lt ${timeRange?.[1]}`;
    dateFilterTxt = ` and ${mSt} and ${mEd}`;
  } else {
    dateFilterTxt = `meeting_start le ${timeRange?.[0]} and meeting_end ge ${timeRange?.[1]}`;
  }
  const url = `/api/standard/OData/VUserMeetingInfos?$filter=contains(user_ids,',${userId},')${dateFilterTxt}`;
  const res1: any = await globalAxios.get(url);

  return res1.data;
};
/*获取非目标医生*/
export const fetchNoAimUserContact = async (
  {
    territory_id = [],
    user_id = [],
    is_speaker,
    physician_name,
    institution_name,
    department_name,
  }: {
    territory_id?: string[];
    user_id?: string[];
    is_speaker?: boolean;
    physician_name?: string;
    institution_name?: string;
    department_name?: string;
  } = {},
  paginationParams: { page_count?: number; page_index?: number } = {}
) => {
  let filterTxt = "";
  if (!isPc) {
    if (physician_name) {
      filterTxt = `&$filter=user_id eq '${user_id}' and (contains(physician_name,'${physician_name}') or 
        contains(institution_name,'${physician_name}') or contains(department_name,'${physician_name}'))`;
    } else if (user_id) {
      filterTxt = `&$filter=user_id eq '${user_id}'`;
    }
  } else {
    filterTxt = `&$filter=user_id eq '${user_id}'`;
    let sArr = [];
    if (physician_name) {
      sArr.push(`contains(physician_name,'${physician_name}')`);
    }
    if (institution_name) {
      sArr.push(`contains(physician_name,'${institution_name}')`);
    }
    if (department_name) {
      sArr.push(`contains(physician_name,'${department_name}')`);
    }
    let sTxt = "";
    if (sArr.length) {
      sTxt = ` and (` + sArr.join(` and `) + `)`;
    }
    filterTxt = filterTxt + sTxt;
  }
  const path = `/api/standard/OData/UserContacts?$count=true${filterTxt}
    &$skip=${
      paginationParams.page_index * paginationParams.page_count -
      paginationParams.page_count
    }&$top=${paginationParams.page_count}`;
  const res = await globalAxios.get(path);
  return {
    array: res.data,
    count: res.count,
  };
};
/** 获取用户关联医生 */
export const fetchUserRelationPhysicians = async (
  {
    territory_id = [],
    user_id = [],
    is_speaker,
    physician_name,
    institution_name,
    department_name,
  }: {
    territory_id?: string[];
    user_id?: string[];
    is_speaker?: boolean;
    physician_name?: string;
    institution_name?: string;
    department_name?: string;
  } = {},
  paginationParams: { page_count?: number; page_index?: number } = {}
) => {
  if (is_speaker) {
    let filterTxt = "";
    if (physician_name) {
      filterTxt = `&$filter=contains(physician_name,'${physician_name}') or 
      contains(institution_name,'${physician_name}') or contains(department_name,'${physician_name}')`;
    }
    const path = `/api/standard/OData/BaseSpeakers?$count=true${filterTxt}
    &$skip=${
      paginationParams.page_index * paginationParams.page_count -
      paginationParams.page_count
    }&$top=${paginationParams.page_count}`;
    const res = await globalAxios.get(path);
    return {
      array: res.data,
      count: res.count,
    };
    // if (isPc) {
    // } else {
    //   return res.data;
    // }
  } else {
    const path = "/api/webmeetingapi/api/Meeting/GetUserAlignPhysician";
    let data = {};
    if (isPc) {
      data = {
        territory_id,
        user_id,
        product_id: [],
        ...paginationParams,
        ...(physician_name && { physician_name }),
        ...(institution_name && { institution_name }),
        ...(department_name && { department_name }),
        ...(is_speaker && { is_speaker }),
      };
    } else {
      data = {
        territory_id,
        user_id,
        product_id: [],
        ...paginationParams,
        // ...(physician_name && { physician_name }),
        search_key: physician_name,
        ...(is_speaker && { is_speaker }),
      };
    }
    const res: any = await globalAxios.post(path, data);
    return {
      array: res.data.data,
      count: res.data.data_count,
    };
  }
};
// 根据类型同步epay数据
export const asyncEpayData = async (data_type: any, user_id: any) => {
  const path = `/api/webmeetingapi/SyncData/Sync?data_type=${data_type}&user_id=${user_id}`;
  const res = await globalAxios.post(path);
  return res.data;
};
// status 同步完成 同步完成否
export const getLogEtl = async (log_etl_id: any) => {
  const path = `/api/standard/OData/LogEtls?$filter=log_etl_id eq '${log_etl_id}'`;
  const res = await globalAxios.get(path);
  return res.data;
};
/** 获取企业微信配置 /api/weixin/Config -> /api/weixin/QYWeChat/GetConfig */
export const getEnterpriseConfig = async () => {
  const path = "/api/weixin/QYWeChat/GetConfig";
  const res = await globalAxios.get(path);
  return res.data;
};

/** 获取微信 sdk 签名 /api/webmeetingapi/api/WeChat/CompanyWechatSign -> /api/weixin/QYWeChat/GetSignature */
export const getWechatSdkSign = async () => {
  const path = "/api/weixin/QYWeChat/GetSignature";
  const URL = window.location.href; // + + window.location.search;
  const data = {
    noncestr: Math.random().toString().slice(2),
    timestamp: new Date().getTime(),
    msg_type: "extends",
    URL,
    // msg_type: "msg_type",
  };
  const res = await globalAxios.post(path, data);
  const config = await getEnterpriseConfig();
  return {
    sign: res.data,
    ...data,
    appid: config.appid,
  };
};

/** 获取公众号跳转地址 */
export const getWechatPublicAccountUrl = async (data: {
  territory_id?: string;
  physician_id?: string;
  user_id?: string;
  meet_info_id?: string;
  msg_type: "sign" | "invite" | "invite_speaker" | "invite_physician";
  url?: string;
}) => {
  const path = "/api/webmeetingapi/api/WeChat/GetWxAccountImageUrl";
  const res = await globalAxios.post(path, data);
  const url = res.data.data.url;
  return url;
};

/** 获取 kvp 字典数据 */
export const fetchKvpDictionaries = async () => {
  const res = await KvpKeysApiFactory().get();
  return res.data as any as KvpKey[];
};
/** 修改添加日程相关人员信息 */
export const putSchedulePerson = async (
  data: Partial<SchedulePhysicianMarketer>[]
) => {
  const res = await SchedulePhysicianMarketersApiFactory().put_7(data as any);
  return res.data;
};

/** 改变会议状态 */
export const changeMeetingStatus = async ({
  meeting_info_id,
  meeting_state,
  user_id,
  user_name,
  territory_id,
  territory_name,
  territory_type,
}: {
  meeting_info_id: string;
  meeting_state: MEETING_STATUS;
  user_id: any;
  user_name: any;
  territory_id: any;
  territory_name: any;
  territory_type: any;
}) => {
  let res: any;
  let data = {
    meeting_info_id,
    user_id,
    user_name,
    territory_id,
    territory_name,
    territory_type,
  };
  if (meeting_state === MEETING_STATUS.AFTER) {
    const url = "/api/webmeetingapi/api/meeting/End";
    res = await globalAxios.post(url, data);
  } else if (meeting_state === MEETING_STATUS.EXECUTE) {
    const url = "/api/webmeetingapi/api/Meeting/Start";
    res = await globalAxios.post(url, data);
  } else if (meeting_state === MEETING_STATUS.COMPLETE) {
    const url = "/api/webmeetingapi/api/meeting/Complete";
    res = await globalAxios.post(url, data);
  } else {
    res = await saveMeetingInfo({
      meeting_info_id,
      meeting_state,
    });
  }
  return res.data;
};

export interface TerritoryMeetingListFilter {
  /** 负责人Id */
  meeting_principal_id?: string;
  /** 岗位 id */
  territory_id?: string;
  /** 0 全部医生 1 我负责的 */
  query_type?: 0 | 1;
  /** 下属名称 */
  subordinate_name?: string;
  /** 参与方式 会场工作  邀请医生 多选 */
  staff_type?: string[];
  /** 会议状态 */
  meeting_states?: number[];
  /** 开始日期 */
  start_time?: string;
  /** 结束日期 */
  end_time?: string;
}

/** 用户辖区会议 */
export const fetchUserTerritoryMeetingList = async (
  filter: TerritoryMeetingListFilter = {}
) => {
  const url = "/api/webmeetingapi/api/MyAccount/GetMyMeetingJurisdiction";

  const res = await globalAxios.post(url, filter);
  res.data.count = res.count;
  return returnApiData(isPc, res.data);
  // return res.data.data as MeetingInfo[];
};

/** 提交会议前请求是否可以提交 */
export const CheckToEpay = async (meeting_info_id: any) => {
  const url = `/api/webmeetingapi/api/Meeting/CheckToEpay?meeting_info_id=${meeting_info_id}`;

  const res = await globalAxios.post(url);

  return res.data;
  // return res.data.data as MeetingInfo[];
};

/** 医生手机申诉 */
export const physicianPhoneAppeal = (data: PhysicianPhoneAppealParams) => {
  const url = "/api/webmeetingapi/api/MyAccount/PhysicianPhoneAppeal";
  return globalAxios.post(url, data).then((res) => {
    if (res.data.code === "Fail") {
      throw new Error();
    }
    return res;
  });
};

/** 公众号注册信息检查信息获取 */
export const getPhysicianCheckInfo = (data: any) => {
  const url = "/api/webmeetingapi/api/WeChat/CheckRegister";
  return globalAxios.post(url, data).then((res) => res.data);
};

/** 下载会议所有证据链 */
export const downloadEvidenceAll = (meeting_info_id: string) => {
  const url = `/api/webmeetingapi/api/Meeting/DownloadMeetingDocuments?meeting_info_id=${meeting_info_id}`;
  window.open(url);
};

/** 获取下载列表 */
export const getDownloadList = (user_id?: string, downloadType?: string) => {
  let url = `/api/standard/OData/vDownloads?$filter=user_id eq '${user_id}'`;

  if (downloadType) {
    url += ` and download_type_kvp eq '${downloadType}'`;
  }

  url += "&$orderby=download_time desc";

  return globalAxios.get(url).then((res) => res.data);
};

/** 创建下载任务 */
export const createDownloadTask = (params: {
  project_id?: string;
  user_id?: string;
}) => {
  const url = "/api/webmeetingapi/Project/DownloadProjectDocuments";
  return globalAxios.get(url, { params });
};

/** 导出所有项目的会议清单 */
export const exportAllProjectMeetings = (user_id?: string) => {
  const url = `/api/webmeetingapi/Project/ExportAllProjectMeetings?user_id=${user_id}`;
  window.open(url);
};

/** 下载所有项目的会议清单 */
export const downloadAllProjectMeetings = (user_id?: string) => {
  const url = `/api/webmeetingapi/Project/DownloadAllProjectMeetings?user_id=${user_id}`;
  return globalAxios.get(url)
};

/** 根据 id 下载文件 */
export const downloadFile = (id: string) => {
  const url = `/api/webmeetingapi/Download/DownloadFile?download_id=${id}`;
  window.open(url);
};

/** 检查是否有飞剑 */
export const checkInspectionReportExists = (meeting_info_id: string) => {
  const url = `/api/webmeetingapi/api/Meeting/CheckInspectionReportExists?meeting_info_id=${meeting_info_id}`;
  return globalAxios.get(url).then((res) => res.data?.data);
};

/** 下载飞检报告 */
export const downloadInspectionReport = (meeting_info_id: string) => {
  const url = `/api/webmeetingapi/api/Meeting/DownloadInspectionReport?meeting_info_id=${meeting_info_id}`;
  window.open(url)
};
