export enum MEETING_STATUS {
  /** 申请 */
  APPLY = 1,
  /** 审批 */
  APPROVE = 2,
  /** 准备 */
  PREPARE = 3,
  /** 执行 */
  EXECUTE = 4,
  /** 会后 */
  AFTER = 5,
  /** 完成 */
  COMPLETE = 6,
  /** 审核 */
  AUDIT = 7,
}
// 0审批拒绝，1审批通过，2审批中，3审批返回，4召回, null未提交
export enum MEETING_TYPE {
  /** 拒绝 */
  REFUSED = 0,
  /** 审核通过 */
  APPROVE = 1,
  /** 审核通过 */
  APPROVING = 2,
  /** 召回中 */
  RECALL = 4,
  /** 已召回 */
  RECALLED = 3,
}

export enum MEETING_PERSON_TYPE {
  /** 讲者 */
  speaker = '讲者',
  /** 主席主持 */
  chairman = '主席/主持',
}

export enum MEETING_WORK_TYPE {
  field = '会场工作',
  invite = '邀请医生',
  join = '参会'
}

export enum MeetingRole {
  /** 申请人 */
  applicant = '申请人',
  /** 参会员工：邀请参会 */
  staffInvite = '参会员工：邀请参会',
  /** 参会员工：会场工作 */
  staffField = '参会员工：会场工作',
  /** 联合负责人 */
  coHead = '联合负责人',
}
